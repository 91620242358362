import React from "react"
import "../styles/index.css"

export default function Home() {
  return (
    <div id="wrapper">
      <h1>TopDownRPG</h1>
      <p>Chill Vibes, Spicy Takes.</p>
    </div>
  )
}
